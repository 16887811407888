import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';


export const GalleryContainer = styled.div`
  color: #fff;
  background: #010606;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: max-content;
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 0 0;
  padding-right: 0;

`

export const SlideWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 900px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin: 0;
  /* FOR ALIGNING TEXT IN THE CENTER OF SLIDE */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  @media screen and (max-height:768px){
    height: max-content;
  }
`

export const SlideContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SlideH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px){
      font-size: 40px;
  }

  @media screen and (max-width: 480px){
      font-size: 32px;
  }
`

export const SlideP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px){
      font-size: 21px;
      margin-top: 20px;
  }

  @media screen and (max-width: 480px){
      font-size: 18px;
      margin-top: 12px;
  }
`

export const Button = styled(Link)`
  border-radius: 50px;
  background: #022999;
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  outline: 3px solid #172344;
  border: 3px solid #142A6F;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: 24px;
  text-decoration: none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #0242cb;
    border: 3px solid #142A6F;
  }

  @media screen and (max-width: 768px){
    margin-top: 12px;
    padding: 8px 20px;
  }

  @media screen and (max-width: 480px){
    margin-top: 8px;
    padding: 6px 16px;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`